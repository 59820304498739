<template>
  <div @mouseleave="setMouseLeaveFn" @mouseenter="setMouseEnterFn">
    <el-carousel
      ref="carouselRef"
      :arrow="(dataMap.arrow as any)"
      :indicator-position="(dataMap.indicatorPosition as any)"
      :direction="(dataMap.direction as any)"
      :trigger="(dataMap.trigger as any)"
      :loop="dataMap.loop"
      :autoplay="dataMap.autoplay"
      :interval="dataMap.interval"
      :pause-on-hover="dataMap.pauseOnHover"
      @change="carouselChangeFn"
      style="width: 100%"
      :height="(dataMap.height as any)"
    >
      <el-carousel-item v-for="item in dataList" :key="item.id">
        <!-- 视频 -->
        <div v-if="isVideoFn(item.src)" style="height: 100%">
          <video
            :ref="setItemRef"
            style="width: 100%; height: 100%"
            x5-playsinline
            playsinline
            webkit-playsinline
            muted
          >
            <source :src="item.src" />
          </video>
        </div>
        <!-- 图片 -->
        <div v-else style="height: 100%">
          <a
            v-if="item.target"
            :href="item.target"
            :target="item.openType === 1 ? '_blank' : '_self'"
          >
            <el-image
              :src="item.src"
              style="height: 100%; width: 100%"
              fit="cover"
            />
          </a>
          <el-image
            v-else
            :src="item.src"
            style="height: 100%; width: 100%"
            fit="cover"
          />
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script lang="ts" setup>
import { getFileType } from '@/utils/index'
interface DataList {
  id: number
  src: string
  target: string
  openType: string | number // 1 打开新的窗口 2 页面内部打开链接
}

const props = defineProps({
  dataOpt: {
    type: Object,
    default: () => {
      return {}
    }
  },
  dataList: {
    type: Array as unknown as PropType<[DataList]>,
    default: () => {
      return []
    }
  }
})
const dataMap = reactive({
  hoverFlag: false, // 鼠标是否滑到banner
  loop: true,
  autoplay: true,
  interval: 3000,
  height: '500px',
  pauseOnHover: true,
  arrow: 'never',
  trigger: 'click',
  direction: 'horizontal',
  indicatorPosition: 'none',
  ...props.dataOpt
})
const itemRefs: any = []

if (props.dataList.length <= 1) {
  dataMap.loop = false
  dataMap.autoplay = false
  dataMap.indicatorPosition = 'none'
}

const carouselRef: any = ref<Element | null>(null)

const setMouseEnterFn = () => {
  // 手动修改因视频问题导轮播图停止的问题
  dataMap.hoverFlag = true
}
const setMouseLeaveFn = () => {
  // 手动修改因视频问题导轮播图停止的问题
  dataMap.autoplay = true
  dataMap.hoverFlag = false
}

const isVideoFn = (src: string): boolean => {
  const videoTypeArr: Array<string> = ['mp4', 'wmv', 'avi', 'mpg', 'mpeg']
  const videoType: any = getFileType(src)
  return videoTypeArr.includes(videoType)
}
const videoList = props.dataList.filter(item => {
  return isVideoFn(item.src)
})

const carouselChangeFn = (index: any) => {
  const currentItem = props.dataList[index]
  const refIndex = videoList.findIndex(item => item.id === currentItem.id)
  try {
    itemRefs.forEach((item: any) => {
      item.currentTime = 0
      item.pause()
    })
  } catch (error) {}
  if (refIndex !== -1) {
    // 当前轮播图是视频需要播放完毕再自动轮播
    const currentDom = itemRefs[refIndex]
    dataMap.autoplay = false
    currentDom.currentTime = 0
    currentDom.play()
    const addEventFn = () => {
      // 播放结束
      console.log('播放结束')
      if (dataMap.hoverFlag) return // 鼠标没滑走不进行下一个操作
      if (props.dataList.length > 1) {
        const newIndex = props.dataList.length - 1 === index ? 0 : index + 1
        carouselRef.value.setActiveItem(newIndex)
        dataMap.autoplay = true
      }
      currentDom.removeEventListener('ended', addEventFn)
    }
    currentDom.addEventListener('ended', addEventFn, false)
  }
}
const setItemRef = (el: any) => {
  if (el) {
    itemRefs.push(el)
  }
}

onMounted(() => {
  // 初始化第一条数据
  carouselChangeFn(0)
})
</script>

<style lang="scss" scoped></style>

<template>
  <div class="home-page">
    <div class="banner-wrap">
      <div class="banner-box">
        <div class="banner_l">
          <div class="banner_tit specialFonts">
            革命性的云端导播技术<br />腾讯会议直播伴侣
          </div>
          <div class="banner_des">
            打造会议、直播、导播一体化场景，专业直播解决方案，助力营销成功。
          </div>
          <div class="banner_btn">
            <div class="home-header-btn-default" @click="goFreeExperience">
              免费体验
            </div>
            <el-button
              type="primary"
              class="home-header-btn-primary"
              @click="handleOpen"
              >立即咨询</el-button
            >
          </div>
        </div>
        <div class="banner_r">
          <div class="banner_r_box">
            <Carousel
              v-if="dataMap.bannerData.length"
              :dataOpt="{
                interval: 3000,
                indicatorPosition: 'outside',
                height: '488px'
              }"
              :dataList="dataMap.bannerData"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="content-wrap">
      <!-- 场景1 -->
      <div class="scene-1">
        <div class="scene-1_l">
          <img src="~/assets/images/home/scene-1.png" alt="" />
        </div>
        <div class="scene-1_r">
          <div class="scene-1_tit specialFonts">
            高效云导播快速搭建 专业直播场景
          </div>
          <div class="scene-1_des">
            10分钟实现全球多地多人多画面无绿幕实时虚拟会议<br>企业级新一代高清虚拟直播+多点流畅互动平台
          </div>
          <ul class="scene-1_list">
            <li>腾讯会议直播伴侣</li>
            <li>多营销场景无缝嵌入</li>
            <li>多视觉切换直播</li>
            <li>无光电设备赛事直播</li>
            <li>跨地同频直播</li>
          </ul>
        </div>
      </div>
      <!-- 场景2 -->
      <div class="scene-2">
        <div class="scene-2_l">
          <div class="tabulation_des_box">
            <div class="tabulation_des_box_tit specialFonts">多场景应用</div>
            <div class="tabulation_des_box_des">
              可满足不同场景用户需求，多达几百种虚拟场景可选择
            </div>
          </div>
          <div class="border-line"></div>
          <ul class="tabulation">
            <li
              v-for="item in dataMap.scene2TypeNameList"
              :key="item.id"
              @click="scene2TypeNameClickFn(item.id)"
              :class="{ active: dataMap.scene2SelectIndex === item.id }"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div class="scene-2_r">
          <div class="scene-2_r_img">
            <img
              v-show="dataMap.scene2SelectIndex === item.id"
              v-for="item in dataMap.scene2TypeNameList"
              :key="item.id"
              :src="getImageAbsolutePath(item.icon)"
              alt=""
            />
          </div>
          <div class="scene-2_r_des">
            <div
              class="des-item"
              v-for="(item, index) in dataMap.scene2Data[
                dataMap.scene2SelectIndex
              ]"
              :key="index"
            >
              <p class="des-item_img">
                <img :src="getImageAbsolutePath(item.icon)" alt="" />
              </p>
              <p class="des-item_des">{{ item.des }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 场景3 -->
      <div class="scene-3">
        <div class="scene-3_tit_hea specialFonts">优势功能</div>
        <div class="scene-3_tab_hea">
          <div
            v-for="item in dataMap.scene3Data"
            :key="item.id"
            @click="scene3TypeNameClickFn(item.id)"
          >
            <img
              class="active-icon"
              :src="getImageAbsolutePath(item.activeIcon)"
              v-show="dataMap.scene3SelectIndex === item.id"
              alt=""
            />
            <img
              class="base-icon"
              :src="getImageAbsolutePath(item.icon)"
              v-show="dataMap.scene3SelectIndex !== item.id"
              alt=""
            />
            <p :class="{ active: dataMap.scene3SelectIndex === item.id }">
              {{ item.des }}
            </p>
          </div>
        </div>
        <div class="scene-3_img_wrap">
          <img
            v-for="item in dataMap.scene3Data"
            v-show="dataMap.scene3SelectIndex === item.id"
            :key="item.id"
            :src="getImageAbsolutePath(item.contentImageSrc)"
            alt=""
          />
        </div>
      </div>
      <!-- 场景4 -->
      <div class="scene-4">
        <div class="scene-4_tit_hea specialFonts">核心优势</div>
        <div class="scene-4_tit_content">
          <div
            class="content_item"
            v-for="item in dataMap.scene4Data"
            :key="item.id"
            :style="{
              'background-image': `url(${getImageAbsolutePath(item.bodyIcon)})`
            }"
          >
            <div class="content_item_hea">
              <p class="item_hea_p1">{{ item.tit }}</p>
              <p class="item_hea_p2">{{ item.des }}</p>
            </div>
            <div class="content_item_bod" :class="item.bodyClass">
              <div
                class="bod_item"
                v-for="(flr, flrIndex) in item.children"
                :key="flrIndex"
              >
                <p class="item_check">
                  <img :src="getImageAbsolutePath(item.icon)" alt="" />
                </p>
                <p class="item_des">
                  {{ flr }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 场景5 -->
      <div class="scene-5">
        <div class="scene-5_tit_hea specialFonts">多版本满足不同需求场景</div>
        <div class="scene-5_tit_content">
          <div
            class="content_item"
            v-for="item in dataMap.scene5Data"
            :key="item.id"
          >
            <div class="content_item_hea" :class="item.headClass">
              <p class="item_hea_p1 specialFonts">{{ item.tit }}</p>
              <p class="item_hea_p2">{{ item.price }}</p>
            </div>
            <div class="content_item_bod">
              <div class="bod_item" v-for="flr in item.children" :key="flr.id">
                <p class="item_des_l">{{ flr.tit }}</p>
                <p class="item_des_r">
                  {{ flr.des }}
                  <img
                    v-if="!flr.des"
                    src="~/assets/images/home/home-small-check.png"
                    alt=""
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="scene-5_foot">
          <p @click="handleOpen">立即咨询</p>
        </div>
      </div>
    </div>
    <!-- 免费试用 -->
    <free-trial />
  </div>
  <CustomerService
    :isShowCustomerServiceWindow="isShowCustomerServiceWindow"
    @handleCloseCustomerService="handleCloseCustomerService"
  />
</template>

<script setup lang="ts">
const seoDescription = ref<string>(
  '打造会议、直播、导播一体化场景，专业在线会议、企业直播、教育直播、医疗直播、电商直播、自媒体直播解决方案'
)
const seoKeywords = ref<string>(
  '企业直播,网络研讨会,云导播,医疗直播,视频会议、腾讯会议、电商直播、自媒体直播、直播伴侣、obs、VMIX、rtc'
)
const seoTitle = ref<string>(
  '100Mix导播台，革命性的云端导播技术创造精彩视觉体验'
)
useHead({
  title: seoTitle,
  meta: [
    { name: 'description', content: seoDescription },
    { name: 'keywords', content: seoKeywords }
  ]
})

import { goFreeExperience } from '@/utils/index'
const isShowCustomerServiceWindow = ref<boolean>(true)
import { homeData } from '@/utils/staticData'
const dataMap = reactive({
  bannerData: [] as any,
  scene2SelectIndex: 0,
  scene3SelectIndex: 0,
  scene2TypeNameList: homeData.scene2TypeNameList,
  scene2Data: homeData.scene2Data,
  scene3Data: homeData.scene3Data,
  scene4Data: homeData.scene4Data,
  scene5Data: homeData.scene5Data
})

const glob: any = import.meta.glob('~/assets/images/home/*', {
  eager: true
})

const getImageAbsolutePath = (imageName: string): string => {
  try {
    return glob[`/assets/images/home/${imageName}`]['default']
  } catch (error) {
    return ''
  }
}
const scene2TypeNameClickFn = (id: number) => {
  dataMap.scene2SelectIndex = id
}
const scene3TypeNameClickFn = (id: number) => {
  dataMap.scene3SelectIndex = id
}
const getBannerFn = async () => {
  const res = await useApiHomeBannerList({
    pageNum: 1,
    pageSize: 10,
    param: {
      sort: 1,
      status: 1
    }
  })
  if (res.code === 200) {
    const dataObj = res.data || {}
    const list = dataObj.records || []
    dataMap.bannerData = list.map((item: any) => {
      const { id, url, targetUrl, openType } = item
      return { id, src: url, target: targetUrl, openType }
    })
  }
}

const handleOpen = () => {
  isShowCustomerServiceWindow.value = true
}
const handleCloseCustomerService = (flag: boolean) => {
  isShowCustomerServiceWindow.value = flag
}
const handleGetSeoData = () => {
  // 10000 首页
  getSeoData(10000).then((res: any) => {
    if (res.code === 200) {
      let data = res.data
      if (data.title) {
        seoTitle.value = data.title
      }
      if (data.description) {
        seoDescription.value = data.description
      }
      if (data.keywords) {
        seoKeywords.value = data.keywords
      }
    }
  })
}

getBannerFn()
handleGetSeoData()
</script>

<style lang="less" scoped>
.home-page {
  font-family: PingFangSC-Medium, PingFang SC;

  .banner-wrap {
    height: 800px;
    background-image: url('~/assets/images/home/home-banner.png');
    background-size: 100% 100%;
    padding-top: 72px;
    .banner-box {
      width: 1400px;
      padding-top: 120px;
      margin: auto;
      display: flex;
    }
    .banner_l {
      padding-top: 72px;
      width: 406px;
      flex: 1;
      .banner_btn {
        display: flex;
        padding-right: 20px;
      }
      .home-header-btn-default {
        width: 200px;
        height: 48px;
        border-radius: 1px;
        border: 1px solid #1ab370;
        font-size: 15px;
        font-weight: 500;
        color: #1ab370;
        text-align: center;
        line-height: 48px;
        margin-right: 20px;
        cursor: pointer;
      }
      .home-header-btn-primary {
        width: 200px;
        height: 48px;
        background: #1ab370;
        border-radius: 1px;
        font-size: 15px;
        font-weight: 500;
        color: #ffffff;
      }
      .banner_tit {
        // word-break: keep-all;
        // width: 480px;
        height: 120px;
        font-size: 48px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 60px;
        background: linear-gradient(
          133deg,
          #00a7ff 0%,
          #00fff1 56%,
          #00b6ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .banner_des {
        margin: 16px 0 32px;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
        padding-right: 20px;
      }
    }
    .banner_r {
      width: 994px;
      margin-right: -74px;
      .banner_r_box {
        padding: 41px 98px 79px;
        box-sizing: border-box;
        height: 608px;
        background-image: url('~/assets/images/home/home-banner-box.png');
        background-size: 100% 100%;
      }
    }
  }
  .content-wrap {
    width: 1400px;
    margin: auto;
    // background: #0a0f1d;
    .scene-1 {
      display: flex;
      margin: 87px 0;
      .scene-1_l {
        img {
          display: block;
          width: 856px;
          height: 626px;
        }
      }
      .scene-1_r {
        margin-left: 132px;
        padding-top: 162px;
        .scene-1_tit {
          font-size: 40px;
          font-weight: 500;
          color: #ffffff;
          line-height: 56px;
          background: linear-gradient(
            133deg,
            #00a7ff 0%,
            #00fff1 56%,
            #00b6ff 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .scene-1_des {
          margin: 16px 0 20px;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          line-height: 22px;
          opacity: 0.8;
        }
        .scene-1_list {
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          line-height: 22px;
          opacity: 0.8;
          li {
            position: relative;
            padding-left: 16px;
          }
          li::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            width: 8px;
            height: 8px;
            background: #ffffff;
            opacity: 0.8;
            border-radius: 100%;
            transform: translate(0, -50%);
          }
        }
      }
    }
    .scene-2 {
      display: flex;
      height: 800px;
      background: #28303b;
      .scene-2_l {
        padding: 61px 30px 0 48px;
        box-sizing: border-box;
        .tabulation_des_box_tit {
          width: 200px;
          height: 56px;
          font-size: 40px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 56px;
          background: linear-gradient(
            133deg,
            #00a7ff 0%,
            #00fff1 56%,
            #00b6ff 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .tabulation_des_box_des {
          width: 272px;
          height: 50px;
          font-size: 16px;
          font-weight: 400;
          color: #9598a2;
          line-height: 22px;
          margin: 8px 0 18px;
        }
        .border-line {
          width: 280px;
          height: 1px;
          background: #000000;
        }
        .tabulation {
          margin-top: 22px;
          font-size: 24px;
          font-weight: 400;
          color: #9598a2;
          line-height: 33px;
          li {
            padding-left: 16px;
            margin: 4px;
            height: 54px;
            line-height: 54px;
            cursor: pointer;
          }
        }
        .active {
          width: 280px;
          background: linear-gradient(
            90deg,
            #516379 0%,
            rgba(40, 52, 68, 0) 100%
          );
          border-radius: 4px;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .scene-2_r {
        width: 1042px;
        .scene-2_r_img {
          width: 1042px;
          height: 590px;
          overflow: hidden;
          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
        .scene-2_r_des {
          display: flex;
          padding: 24px 60px 0;
          justify-content: space-around;
          height: 210px;
          background: #363f4c;
          box-sizing: border-box;
          .des-item {
            width: 200px;
            text-align: center;
          }
        }
        .des-item_img {
          height: 80px;
          img {
            display: inline-block;
          }
        }
        .des-item_des {
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
          opacity: 0.8;
        }
      }
    }
    .scene-3 {
      margin-top: 10px;
      .scene-3_tit_hea {
        padding: 69px 0;
        font-size: 40px;
        font-weight: 500;
        color: #ffffff;
        line-height: 56px;
        background: linear-gradient(
          133deg,
          #00a7ff 0%,
          #00fff1 56%,
          #00b6ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
      }
      .scene-3_tab_hea {
        display: flex;
        justify-content: space-between;
        div {
          cursor: pointer;
          text-align: center;
        }
        img {
          display: inline-block;
        }
        p {
          height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 22px;
          opacity: 0.6;
          &.active {
            color: #1ab370;
            opacity: 1;
          }
        }
      }
      .scene-3_img_wrap {
        position: relative;
        margin-top: 48px;
        width: 1400px;
        height: 720px;
        background-image: url('~/assets/images/home/scene-3_bj.png');
        background-size: 100% 100%;
        img {
          position: absolute;
          left: 50%;
          top: 50%;
          display: block;
          transform: translate(-50%, -50%);
          width: 1092px;
          height: 614px;
          border-radius: 8px;
        }
      }
    }
    .scene-4 {
      padding: 101px 0 132px;
      .scene-4_tit_hea {
        text-align: center;
        padding: 80px 0;
        font-size: 40px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 56px;
        background: linear-gradient(
          133deg,
          #00a7ff 0%,
          #00fff1 56%,
          #00b6ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .scene-4_tit_content {
        display: flex;
        justify-content: space-between;
      }
      .content_item {
        width: 660px;
        height: 720px;
        // background-image: url('~/assets/images/home/scene-4_1.png');
        background-size: 100% 100%;
      }
      .content_item_hea {
        height: 240px;
        padding: 82px 0 0 92px;
        box-sizing: border-box;
        p {
          margin: 0;
          padding: 0;
        }
        .item_hea_p1 {
          font-size: 32px;
          font-family: PingFang-SC-Heavy, PingFang-SC;
          font-weight: 800;
          color: #ffffff;
        }
        .item_hea_p2 {
          font-size: 24px;
          font-family: ArialMT;
          color: #ffffff;
          opacity: 0.6;
        }
      }
      .content_item_bod {
        padding: 82px 0 0 80px;
        height: 480px;
        box-sizing: border-box;

        box-shadow: inset 0px 4px 2px 0px #ffffff;
        border-radius: 16px 16px 4px 4px;
        &.content_item_bod1 {
          background: linear-gradient(
            159deg,
            rgba(248, 248, 248, 0.92) 0%,
            rgba(238, 238, 238, 0.5) 100%,
            rgba(238, 238, 238, 0.5) 100%
          );
        }
        &.content_item_bod2 {
          background: linear-gradient(
            169deg,
            rgba(248, 248, 248, 0.96) 0%,
            rgba(238, 238, 238, 0.5) 100%,
            rgba(238, 238, 238, 0.5) 100%
          );
        }
        .bod_item {
          display: flex;
          padding-right: 60px;
          &:not(:last-child) {
            margin-bottom: 28px;
          }
        }
        .item_des {
          margin-left: 16px;
          font-size: 20px;
          line-height: 32px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        .item_check {
          padding-top: 6px;
        }
      }
    }
    .scene-5 {
      padding: 0 0 60px;
      .scene-5_tit_hea {
        text-align: center;
        padding: 80px 0;
        font-size: 40px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 56px;
        background: linear-gradient(
          133deg,
          #00a7ff 0%,
          #00fff1 56%,
          #00b6ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .scene-5_tit_content {
        display: flex;
        justify-content: space-between;
        .content_item {
          width: 408px;
          height: 654px;
          background: #2d3644;
          border-radius: 16px 16px 4px 4px;
          border: 1px solid;
          border-image: linear-gradient(
              180deg,
              rgba(255, 255, 255, 0),
              rgba(255, 255, 255, 0.3)
            )
            1 1;
        }
        .content_item_hea {
          position: relative;
          padding-top: 64px;
          box-sizing: border-box;
          height: 228px;
          background: #28303b;
          border-radius: 16px 16px 0px 0px;
          border: 1px solid #333333;
          text-align: center;
          .item_hea_p1 {
            font-size: 32px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            margin-bottom: 16px;
            line-height: 32px;
          }
          .item_hea_p2 {
            font-size: 40px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 40px;
          }
          &.content_item_hea1 {
            .item_hea_p1 {
              background: linear-gradient(270deg, #5effce 0%, #1ab370 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
          &.content_item_hea2 {
            .item_hea_p1 {
              background: linear-gradient(270deg, #35e5ff 0%, #45a6ff 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
          &.content_item_hea3 {
            .item_hea_p1 {
              background: linear-gradient(270deg, #fff2cf 0%, #ffe2a2 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
          &.content_item_hea1::before {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            right: 0;
            height: 12px;
            background: linear-gradient(270deg, #5effce 0%, #1ab370 100%);
            border-radius: 16px 16px 0px 0px;
          }
          &.content_item_hea2::before {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            right: 0;
            height: 12px;
            background: linear-gradient(270deg, #35e5ff 0%, #45a6ff 100%);
            border-radius: 16px 16px 0px 0px;
          }
          &.content_item_hea3::before {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            right: 0;
            height: 12px;
            background: linear-gradient(270deg, #fff2cf 0%, #ffe2a2 100%);
            border-radius: 16px 16px 0px 0px;
          }
        }
        .content_item_bod {
          padding: 36px 48px;
          box-sizing: border-box;
          .bod_item {
            padding: 12px 0;
            display: flex;
            justify-content: space-between;
          }
          .item_des_l {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 22px;
            opacity: 0.6;
          }
          .item_des_r {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 22px;
          }
        }
      }
      .scene-5_foot {
        text-align: center;
        margin-top: 80px;
        p {
          display: inline-block;
          width: 360px;
          height: 48px;
          line-height: 48px;
          background: #1ab370;
          border-radius: 2px;
          cursor: pointer;
          font-size: 20px;
          font-weight: 500;
          color: #ffffff;
          font-family: PingFangSC-Medium, PingFang SC;
        }
      }
    }
  }
}
</style>

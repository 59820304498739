export const homeData = {
  scene2TypeNameList: [
    { id: 0, name: "线上研讨会", icon: "scene-2_1.png" },
    { id: 1, name: "产品发布会", icon: "scene-2_2.jpg" },
    { id: 2, name: "学术会议", icon: "scene-2_3.jpg" },
    { id: 3, name: "企业年会", icon: "scene-2_4.jpg" },
    { id: 4, name: "商业直播", icon: "scene-2_5.jpg" },
    { id: 5, name: "自媒体直播", icon: "scene-2_6.jpg" },
  ],
  scene2Data: [
    [
      {
        tit: "线上研讨会",
        icon: "webinar_1.png",
        des: "网页即开即用无需下载任何软件",
      },
      {
        tit: "线上研讨会",
        icon: "webinar_2.png",
        des: "云端执行，无惧本地网络、系统障碍",
      },
      {
        tit: "线上研讨会",
        icon: "webinar_3.png",
        des: "支持腾讯会议、医百会议等一键接入",
      },
      {
        tit: "线上研讨会",
        icon: "webinar_4.png",
        des: "导播视频信号与会议信号双向互通",
      },
    ],
    [
      {
        tit: "产品发布会",
        icon: "productLaunch_1.png",
        des: "品牌专属logo和场景素材，集成素材商店，海量素材自定义",
      },
      {
        tit: "产品发布会",
        icon: "productLaunch_2.png",
        des: "一站式定制化直播解决方案",
      },
      {
        tit: "产品发布会",
        icon: "productLaunch_3.png",
        des: "AI无绿幕抠像，随时切换场景，播放PPT",
      },
      {
        tit: "产品发布会",
        icon: "productLaunch_4.png",
        des: "支持多平台推流，抖音、快手、B站、微信一键直播",
      },
    ],
    [
      {
        tit: "学术会议",
        icon: "academicConferences_1.png",
        des: "支持数百人连麦会议、支持多分会场跨地域同频直播，结合多地画面编排输出",
      },
      {
        tit: "学术会议",
        icon: "academicConferences_2.png",
        des: "支持Windows、Mac、iPhone、Android、网页、微信小程序多端连麦",
      },
      {
        tit: "学术会议",
        icon: "academicConferences_3.png",
        des: "支持数百人大型线上会议场景",
      },
      {
        tit: "学术会议",
        icon: "academicConferences_4.png",
        des: "基于RTC技术，轻松支持线下会场+线上同时参与",
      },
    ],
    [
      {
        tit: "企业年会",
        icon: "enterpriseAnnualMeeting_1.png",
        des: "海量云端素材，支持虚拟场景自定义",
      },
      {
        tit: "企业年会",
        icon: "enterpriseAnnualMeeting_2.png",
        des: "任务搭配节目单使用，可实现无人值守导播",
      },
      {
        tit: "企业年会",
        icon: "enterpriseAnnualMeeting_3.png",
        des: "节目单可按需配置节目场景，每档节目都支持转场特效、播放时长",
      },
      {
        tit: "企业年会",
        icon: "enterpriseAnnualMeeting_4.png",
        des: "游戏互动，百人连麦",
      },
    ],
    [
      {
        tit: "商业直播",
        icon: "commercialLiveStreaminga_1.png",
        des: "多机位多角度产品展示",
      },
      {
        tit: "商业直播",
        icon: "commercialLiveStreaminga_2.png",
        des: "海量云端素材虚拟场景，支持一键切换",
      },
      {
        tit: "商业直播",
        icon: "commercialLiveStreaminga_3.png",
        des: "抽奖、问卷多种形式活动，多种玩法",
      },
      {
        tit: "商业直播",
        icon: "commercialLiveStreaminga_4.png",
        des: "多平台推流，抖音、快手、B站、微信提升曝光",
      },
    ],
    [
      {
        tit: "自媒体直播",
        icon: "selfMediaLiveStreaming_1.png",
        des: "虚拟场景、AI无绿幕抠像，无惧场地限制，想播就播",
      },
      {
        tit: "自媒体直播",
        icon: "selfMediaLiveStreaming_2.png",
        des: "主播美颜一键开启",
      },
      {
        tit: "自媒体直播",
        icon: "selfMediaLiveStreaming_3.png",
        des: "高清无延迟，内容无限传播",
      },
      {
        tit: "自媒体直播",
        icon: "selfMediaLiveStreaming_4.png",
        des: "精彩回放自动生成，打造长期收益",
      },
    ],
  ],

  scene3Data: [
    {
      id: 0,
      icon: "conferenceSignalInput.png",
      activeIcon: "conferenceSignalInput_active.png",
      des: "会议信号互通",
      contentImageSrc: "conferenceSignalInput_main.jpg",
    },
    {
      id: 1,
      icon: "aICutout.png",
      activeIcon: "aICutout_active.png",
      des: "AI 抠像",
      contentImageSrc: "aICutout_main.jpg",
    },
    {
      id: 2,
      icon: "rtc.png",
      activeIcon: "rtc_active.png",
      des: "RTC",
      contentImageSrc: "rtc_main.jpg",
    },
    {
      id: 3,
      icon: "multiPlatformOperation.png",
      activeIcon: "multiPlatformOperation_active.png",
      des: "多平台操作",
      contentImageSrc: "multiPlatformOperation_main.jpg",
    },
    {
      id: 4,
      icon: "multiPlatformStreaming.png",
      activeIcon: "multiPlatformStreaming_active.png",
      des: "多平台推流",
      contentImageSrc: "multiPlatformStreaming_main.jpg",
    },
    {
      id: 5,
      icon: "cloudTechnology.png",
      activeIcon: "cloudTechnology_active.png",
      des: "云端技术",
      contentImageSrc: "cloudTechnology_main.jpg",
    },
    {
      id: 6,
      icon: "materialStore.png",
      activeIcon: "materialStore_active.png",
      des: "素材商店",
      contentImageSrc: "materialStore_main.jpg",
    },
    {
      id: 7,
      icon: "customizedDevelopment.png",
      activeIcon: "customizedDevelopment_active.png",
      des: "定制化开发",
      contentImageSrc: "customizedDevelopment_main.jpg",
    },
    {
      id: 8,
      icon: "remoteAssistance.png",
      activeIcon: "remoteAssistance_active.png",
      des: "远程协助",
      contentImageSrc: "remoteAssistance_main.jpg",
    },
    {
      id: 9,
      icon: "moreFunction.png",
      activeIcon: "moreFunction_active.png",
      des: "更多功能",
      contentImageSrc: "moreFunction_main.jpg",
    },
  ],
  scene4Data: [
    {
      id: 0,
      tit: "稳定",
      des: "Stabilize",
      bodyClass: "content_item_bod1",
      bodyIcon: "scene-4_1.png",
      icon: "scene-4_1_1.png",
      children: [
        "云端技术，对比OBS、vMix等硬件设备要求低",
        "分布式架构，毫秒级处理，直播清晰不卡顿",
        "多任务处理，百人连麦流畅运行",
        "集成超级会控与远程协助技术，可无缝解决直播过程中遇到的各类技术问题",
      ],
    },
    {
      id: 1,
      tit: "服务",
      des: "Serve",
      bodyClass: "content_item_bod2",
      bodyIcon: "scene-4_2.png",
      icon: "scene-4_2_1.png",
      children: [
        "专业团队，提供定制化整体解决方案",
        "线下执行团队覆盖全国，协助呈现精彩直播",
        "管家服务，7*24小时快速响应",
        "技术支持，专业技术团队实时监测维护",
        "完善的API/SDK，支持定制化开发，与业务系统集成",
      ],
    },
  ],
  scene5Data: [
    {
      id: 0,
      tit: "体验版",
      price: "免费",
      headClass: "content_item_hea1",
      children: [
        { id: "0_1", tit: "AI抠像", des: "2路" },
        { id: "0_2", tit: "腾讯会议集成", des: "5路视频源" },
        { id: "0_3", tit: "视频水印", des: "带水印" },
        { id: "0_4", tit: "2D、3D场景部分免费", des: "" },
        { id: "0_5", tit: "高级美颜", des: "" },
        { id: "0_6", tit: "技术支持", des: "" },
      ],
    },
    {
      id: 1,
      tit: "标准版",
      price: "¥9800元/年",
      headClass: "content_item_hea2",
      children: [
        { id: "1_1", tit: "AI抠像", des: "5路" },
        { id: "1_2", tit: "腾讯会议集成", des: "15路视频源" },
        { id: "1_3", tit: "视频水印", des: "无水印" },
        { id: "1_4", tit: "2D、3D场景全部免费", des: "" },
        { id: "1_5", tit: "高级美颜", des: "" },
        { id: "1_6", tit: "技术支持", des: "" },
        { id: "1_7", tit: "限时", des: "免费试用一个月" },
      ],
    },
    {
      id: 2,
      tit: "企业版",
      price: "¥18800元/年",
      headClass: "content_item_hea3",
      children: [
        { id: "2_1", tit: "AI抠像", des: "10路" },
        { id: "2_2", tit: "腾讯会议集成", des: "30路视频源" },
        { id: "2_3", tit: "视频水印", des: "无水印" },
        { id: "2_4", tit: "2D、3D场景全部免费", des: "" },
        { id: "2_5", tit: "高级美颜", des: "" },
        { id: "2_6", tit: "技术支持", des: "" },
      ],
    },
  ]
} 